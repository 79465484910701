@font-face {
  font-family: "OpenSans-Bold";
  src: url("/fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("/fonts/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("/fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "Oswald-Bold";
  src: url("/fonts/Oswald-Bold.otf");
}
@font-face {
  font-family: "Oswald-Light";
  src: url("/fonts/Oswald-Light.otf");
}
@font-face {
  font-family: "Oswald-Medium";
  src: url("/fonts/Oswald-Medium.ttf");
}
@font-face {
  font-family: "Oswald-Regular";
  src: url("/fonts/Oswald-Regular.otf");
}
@font-face {
  font-family: "Oswald-SemiBold";
  src: url("/fonts/Oswald-SemiBold.ttf");
}
@font-face {
  font-family: "Pacifico";
  src: url("/fonts/Pacifico.ttf");
}
body {
  overflow-x: hidden;
  margin:0;
}
header {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  height: 700px;
}
section {
  justify-content: center;
  align-items: center;
  display: flex;
}
.container {
  width: 1280px;
}
.header-box {
  display: flex;
  justify-content: space-between;
  height: 205px;
  align-items: center;
}
.header-box-left {
  width: 49.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.header-box-separator {
  width: 2px;
  background-color: #fff;
  height: 45%;
  margin: 0 40px;
}
.header-box-right {
  width: 49.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.header-box-left img {
  width: 350px;
}
.header-box-right h2 {
  font-family: "Oswald-Regular", sans-serif;
  margin: 0;
  letter-spacing: 2px;
  font-size: 50px;
  line-height: 55px;
  color: #fff;
}
.header-box-right h2 span {
  font-family: "Oswald-Light", sans-serif;
}
.header-video {
  width: 100%;
  height: auto;
  position: absolute;
}
.header-box-right div:first-child {
  /*padding-left: 40px;*/
}
/*SECTION 2*/
.section-2 {
  background-color: #e0dac0;
  padding-bottom: 75px;
  padding-top: 25px;
  position: relative;
}
.section-2-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-2-right,
.section-2-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-2-right-image {
  background-image: url(/img/Img-05.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.section-2-separator-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}
.section-2-item-image-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-2-separator {
  width: 1px;
  background-color: #47aab1;
  height: 50px;
}
.section-2-item-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.section-2-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.section-2-header img {
  width: 250px;
}
.section-2-item-image-box div:first-child {
  width: 100px;
  height: 100px;
}
.section-2-item-text p {
  font-family: OpenSans-Regular;
  text-align: center;
  letter-spacing: 1px;
  font-size: 18px;
}
.section-2-item-text p strong {
  font-family: OpenSans-SemiBold;
  background-color: #47a9b4;
  padding: 0 4px;
}
.section-2-right-image {
  background-image: url(/img/Img-05.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 800px;
  width: 100%;
}
.section-2-detail-1 {
  position: absolute;
  z-index: 1;
  bottom: 150px;
  right: 0;
}
.section-2-detail-2 {
  position: absolute;
  z-index: 1;
  bottom: 100px;
  left: 0;
}
.section-2-detail-3 {
  position: absolute;
  z-index: 1;
  top: 200px;
  left: 0;
}
/*SECTION 3*/
.section-3 {
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
}
.section-3-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-3-top-line {
  width: 60px;
  height: 1px;
  background-color: #fff;
}
.section-3-top img {
  width: 80px;
  height: 70px;
  margin: 0 10px;
}
.section-3-bottom {
  margin-top: 20px;
}
.section-3-bottom p {
  text-align: center;
  font-family: Oswald-Light;
  color: #fff;
  font-size: 31px;
  letter-spacing: 1px;
  margin: 0;
}
.section-3-bottom p strong {
  font-family: Oswald-Regular;
}
/*SECTION 4*/
.section-4 {
  width: 100%;
  /*padding-top: 50px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0dac0;
  flex-direction: column;
  position: relative;
}
.section-4-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.section-4-square {
  border: 2px solid #eee;
  padding: 70px;
  width: 60%;
}
.section-4-square-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-4-square-top-left {
  width: 50%;
}
.section-4-square-top-left p {
  font-family: Oswald-Medium;
  color: #47a9b7;
  font-size: 135px;
  line-height: 130px;
  text-align: center;
  margin: 0;
  text-shadow: -2px 2px 1px #fff;
}
.section-4-square-top-right {
  width: 35%;
}
.section-4-square-top-right img {
  max-width: 100%;
}
.section-4-square-middle {
  background-color: #256f72;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
.section-4-square-middle-detail-3 {
  letter-spacing: 5px;
  color: #fff;
}
.section-4-square-middle p {
  color: #e0dac0;
  font-family: OpenSans-Bold;
  font-size: 25px;
  margin: 0;
  margin: 0 20px;
  letter-spacing: 2px;
}
.section-4-square-middle-detail-4 {
  letter-spacing: 5px;
  color: #fff;
}
.section-4-square-middle-detail-1 {
  width: 50px;
  height: 50px;
  background-color: #e0dac0;
  transform: rotate(45deg);
  position: absolute;
  left: -25px;
}
.section-4-square-middle-detail-2 {
  width: 50px;
  height: 50px;
  background-color: #e0dac0;
  transform: rotate(45deg);
  position: absolute;
  right: -25px;
}
.section-4-square-bottom {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-4-square-bottom-rectangle-box {
  position: absolute;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-4-square-bottom-rectangle {
  border: 2px solid #256f72;
  width: 100%;
  height: 175px;
}
.section-4-square-bottom-box {
  background-color: #e0dac0;
  z-index: 999;
  padding: 0 45px;
  line-height: 100px;
}
.section-4-square-bottom-box-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-4-square-bottom-box-top div:first-child p {
  color: #256f72;
  font-family: Oswald-Bold;
  font-size: 100px;
  margin: 0;
}
.section-4-square-bottom-box-top div:nth-child(2) p {
  color: #256f72;
  font-family: Oswald-Regular;
  font-size: 40px;
  margin: 0;
  line-height: 45px;
  margin: 0 10px;
}
.section-4-square-bottom-box-top div:nth-child(3) img {
  max-width: 85%;
}
.section-4-square-bottom-box-bottom p {
  color: #256f72;
  font-family: Oswald-Bold;
  font-size: 100px;
  margin: 0;
}

/**/

.section-4-middle {
  background-color: #47a9b4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
.section-4-middle-detail-3 {
  display: flex;
  justify-content: space-around;
  width: 15%;
}
.section-4-middle p {
  color: #276d72;
  font-family: Oswald-Regular;
  font-size: 40px;
  margin: 0;
  margin: 0 30px;
  letter-spacing: 2px;
}
.section-4-middle-detail-4 {
  display: flex;
  justify-content: space-around;
  width: 15%;
}
.section-4-middle-detail-1 {
  width: 80px;
  height: 80px;
  background-color: #e0dac0;
  transform: rotate(45deg);
  position: absolute;
  left: -45px;
}
.section-4-middle-detail-2 {
  width: 80px;
  height: 80px;
  background-color: #e0dac0;
  transform: rotate(45deg);
  position: absolute;
  right: -45px;
}
.section-4-middle-detail-circle {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: #dfd9bf;
}
.section-4-middle-box {
  width: 73%;
  padding: 0 100px;
}
.section-4-middle-bottom-detail {
  color: #fff;
  overflow: hidden;
  letter-spacing: 5px;
  height: 20px;
}
.section-4-bottom {
  width: 100%;
  /*margin-top: 50px;*/
}
.section-4-background-container {
  /*height: 1500px;*/
  overflow: hidden;
}
.section-4-background {
  width: 100%;
  /*height: 100%;*/
  /*background-image: url(/img/pampa.jpg);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.section-4-bottom-title h2 {
  font-family: "Pacifico";
  text-align: center;
  color: #277074;
  font-size: 40px;
  margin: 0;
}
.no-selecteable-text {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.section-4-leaf-detail {
}
.container-100 {
  width: 100%;
}
.section-5 {
  background-color: #fff;
}
.section-5-title {
  /*margin: 55px 0;*/
  margin-top: 55px;
  margin-bottom: 75px;
}
.section-5-title h2 {
  text-align: center;
  color: #0795a3;
  font-family: Oswald-Medium;
  font-size: 40px;
  letter-spacing: 5px;
  line-height: 45px;
  text-shadow: 1px 1px 0px #333;
}
.section-5-map {
  width: 80%;
  height: 400px;
  /*background-color: blue;*/
  margin: 0 auto;
  margin-bottom: 25px;
}
.section-5-carousel {
  width: 80%;
  /*background-color: gray;*/
  margin: 0 auto;
}
.section-6-logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ccc;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
}
.section-6-logo-box img {
  width: 50%;
}
.company-logo-box {
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.company-logo-box img {
  max-width: 100%;
}
.company-container {
  padding: 0 20px;
  cursor: pointer;
  position: relative;
}
.company-info-box {
  word-break: break-all;
}
.company-info-box h6 {
  margin: 0;
  margin-bottom: 0px;
  font-family: OpenSans-SemiBold;
  font-size: 13px;
  margin-bottom: 10px;
}
.company-info-box p {
  margin: 0;
  margin-bottom: 0px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  margin-bottom: 5px;
}
.clearfix {
  overflow: auto;
  zoom: 1;
}
.company-triangle-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.company-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #048b8b transparent transparent transparent;
  display: none;
}
.company-selected .company-triangle-box .company-triangle {
  display: block;
}
.slick-next,
.slick-prev {
  right: -25px;
  width: 42px;
  height: 75px;
}
.slick-next i,
.slick-prev i {
  color: black;
  font-size: 35px;
}
.slick-prev {
  left: -45px;
}
.section-2-right-image {
  position: relative;
}
.map-hover {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}
.map-hover.buenos-aires {
  background-image: url("/img/hover-buenos-aires.png");
}
.map-hover.cordoba {
  background-image: url("/img/hover-cordoba.png");
}
.map-hover.sanluis {
  background-image: url("/img/hover-sanluis.png");
}
.map-hover.tucuman {
  background-image: url("/img/hover-tucuman.png");
}
.map-hover.show {
  z-index: 1;
}
.zone-activator {
  position: absolute;
  cursor: pointer;
}
.buenos-aires-zone-activator {
  z-index: 2;
  width: 130px;
  height: 135px;
  top: 38%;
  left: 50%;
}
.cordoba-zone-activator {
  z-index: 2;
  width: 59px;
  height: 108px;
  top: 23%;
  left: 46%;
}
.sanluis-zone-activator {
  z-index: 2;
  width: 44px;
  height: 90px;
  top: 31%;
  left: 40%;
}
.tucuman-zone-activator {
  z-index: 2;
  width: 45px;
  height: 55px;
  top: 14%;
  left: 42%;
}
.zone-activator-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.modal {
  position: absolute;
  left: 0;
  top: -1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all ease 0.5s;
  z-index: 3;
}
.modal.show {
  top: 1050px;
  opacity: 1;
}
.modal.show.company-modal {
  top: 2900px;
}
.modal-box {
  width: 80%;
  background-color: #fff;
  padding: 5px 20px;
  box-shadow: 1px 1px 3px #000;
  border-radius: 3px;
}
.modal-header {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.modal-header h2 {
  margin: 0;
  font-family: Oswald-Regular;
  color: #47a9b4;
  font-size: 25px;
  letter-spacing: 2px;
}
.modal-body p {
  margin: 0;
  font-family: OpenSans-Regular;
  font-size: 16px;
  margin: 15px 0;
}
.modal-footer {
  display: flex;
  flex-direction: row;
}
.modal-footer-image-box {
  margin-right: 10px;
}
.modal-footer .variety-image,
.modal-footer .company-image,
.modal-footer-products .product-image {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.modal-footer .company-image,
.modal-footer-products .product-image {
  margin-right: 10px;
}
.modal-footer-products .product-image {
  margin-top: 10px;
}
.modal-footer-products {
  margin-top: 10px;
}
.modal-footer-image-box p {
  text-align: center;
  font-family: OpenSans-Regular;
  margin: 10px 0;
  color: #47a9b4;
}
.varieties-subtitle,
.products-subtitle {
  font-family: OpenSans-Bold;
}
.modal-header .close {
  font-size: 25px;
  margin-right: 20px;
  cursor: pointer;
}
#fixed-header {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
  padding: 10px 0;
  top: -100px;
  left: 0;
  transition: all ease 0.2s;
}
#fixed-header.show {
  top: 0;
}
.fixed-header-container img {
  width: 80px;
}
.fixed-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.locations-box {
  display: flex;
}
.location {
  width: 40px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;
  cursor: pointer;
}
.location.es {
  background-image: url(/img/es.png);
}
.location.en {
  background-image: url(/img/en.svg);
}
.location.pt {
  background-image: url(/img/pt.gif);
}
.modal-footer-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-footer-products {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.locations-box div:active {
  box-shadow: 1px 1px 1px #000;
}
.section-4-bottom-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+99&0+0,1+60 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 1) 99%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 1) 99%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 1) 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
}
.section-4-top-gradient {
  background: rgb(224, 220, 189);
  background: -moz-linear-gradient(
    179deg,
    rgba(224, 220, 189, 1) 0%,
    rgba(224, 220, 189, 1) 20%,
    rgba(167, 213, 213, 1) 89%
  );
  background: -webkit-linear-gradient(
    179deg,
    rgba(224, 220, 189, 1) 0%,
    rgba(224, 220, 189, 1) 20%,
    rgba(167, 213, 213, 1) 89%
  );
  background: linear-gradient(
    179deg,
    rgba(224, 220, 189, 1) 0%,
    rgba(224, 220, 189, 1) 20%,
    rgba(167, 213, 213, 1) 89%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e0dcbd",endColorstr="#a7d5d5",GradientType=1);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
}
.section-2-header-box h2 div:first-child {
  font-family: Oswald-Regular;
  color: #47a9b3;
  font-size: 28px;
  letter-spacing: 2px;
}
.section-2-header-box h2 div:nth-child(2) {
  font-family: Oswald-Regular;
  color: #47a9b3;
  font-size: 20px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-2-header-box h2 div:nth-child(2) .dot {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #47a9b3;
  margin: 0 10px;
}
.section-2-header-box h2 div:nth-child(3) {
  font-family: OpenSans-Regular;
  color: #fff;
  text-align: center;
  font-size: 28px;
}
.section-2-header-box h2 div:nth-child(4) {
  font-family: OpenSans-Regular;
  color: #333;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-2-header-box h2 div:nth-child(4) .line {
  width: 20%;
  height: 3px;
  background-color: #333;
  border-radius: 50px;
}
.section-2-header-box h2 div:nth-child(5) {
  font-family: OpenSans-Regular;
  color: #333;
  text-align: center;
  font-size: 42px;
}
.section-2-header-box {
  text-align: center;
}

.company-container {
  outline: none; /* Works in Firefox, Chrome, IE8 and above */
  _nofocusline: expression(this.hideFocus=true); /* Fix for IE7 */
}
.read-more-companies {
  border: none;
  background: none;
  padding: 0;
  font-family: Oswald-Regular;
  color: #0795a3;
  cursor: pointer;
}
.company-modal .modal-box {
  padding-bottom: 20px;
}
.capacity_tag {
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  font-family: Oswald-Regular;
  border-radius: 3px;
  margin-left: 10px;
}

.capacity_tag.high {
  background-color: #398345;
}
.capacity_tag.medium {
  background-color: #df8800;
}
.capacity_tag.special {
  background-color: #9a8176;
}
.capacity_tag.artesanal {
  background-color: #006ec5;
}

.section-4-carousel-item img{
  max-width: 100%;
}

.section-4-carousel-item .slick-prev{
  left: 0 !important;
  z-index: 999 !important;
}

.section-4-carousel-item .slick-prev::before,
.section-4-carousel-item .slick-next::before{
  display: none !important;
}

.section-4-carousel-item .slick-next{
  left: 0 !important;
  z-index: 999 !important;
}

@media screen and (max-width: 1920px) {
  /*.section-4-background-container{*/
  /*height: 2500px;*/
  /*}*/
}
@media screen and (max-width: 1800px) {
  /*.section-4-background-container{*/
  /*height: 2500px;*/
  /*}*/
}
@media screen and (max-width: 1700px) {
  /*.section-4-background-container{*/
  /*height: 2500px;*/
  /*}*/
}
@media screen and (max-width: 1600px) {
  /*.section-4-background-container{*/
  /*height: 2500px;*/
  /*}*/
}
@media screen and (max-width: 1500px) {
  /*.section-4-background-container{*/
  /*height: 2500px;*/
  /*}*/
}
@media screen and (max-width: 1380px) {
  /*.section-4-background-container{*/
  /*height: 1400px;*/
  /*}*/
}
@media screen and (max-width: 1024px) {
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  header {
    height: 400px;
  }
  .section-4-square-top-left p {
    font-size: 7.5em;
    line-height: 125px;
  }
  .section-4-square-top {
    justify-content: space-between;
  }
  .section-4-square-middle-detail-3,
  .section-4-square-middle-detail-4 {
    display: none;
  }
  .section-4-square-bottom-box {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .section-4-middle-detail-3 .section-4-middle-detail-circle:first-child,
  .section-4-middle-detail-4 .section-4-middle-detail-circle:nth-child(4) {
    display: none;
  }
  header {
    height: 210px;
  }
  .header-box-left img {
    width: 75%;
  }
  .header-box-right h2 {
    font-size: 1.5em;
    line-height: 30px;
  }
  .section-2-box {
    flex-direction: column;
  }
  .section-2-right,
  .section-2-left {
    width: 100%;
  }
  .section-3-box {
    padding: 0 30px;
  }
  .section-2-right-image {
    height: 650px;
  }
  .section-4-square {
    padding: 35px 35px 80px 35px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section-4-square-top-left p {
    font-size: 3em;
    line-height: 50px;
  }
  .section-4-square-top-right img {
    max-width: 80%;
  }
  .section-4-square-top {
    justify-content: space-between;
  }
  .section-4-square-middle-detail-3,
  .section-4-square-middle-detail-4 {
    display: none;
  }
  .section-4-square-middle {
    width: 100%;
    margin: 20px 0px 60px 0;
  }
  .section-4-square-bottom-box-top div:first-child p {
    font-size: 3em;
  }
  .section-4-square-bottom-box-top div:nth-child(2) p {
    font-size: 1.2em;
    line-height: 20px;
  }
  .section-4-square-bottom-box-top div:nth-child(3) img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-4-square-bottom-box-top {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-4-square-bottom-box {
    line-height: 55px;
  }
  .section-4-square-bottom-box-bottom p {
    font-size: 4em;
    text-align: center;
  }
  .section-4-square-bottom-rectangle {
    height: 200px;
  }
  .section-4-middle p {
    font-size: 1.5em;
    margin: 0 15px;
  }
  .section-4-middle-box {
    width: 85%;
    padding: 0;
  }
  .section-4-middle-detail-1 {
    width: 50px;
    height: 50px;
    left: -20px;
  }
  .section-4-middle-detail-2 {
    width: 50px;
    height: 50px;
    right: -20px;
  }
  .section-4-square-middle p {
    font-size: 1.3em;
  }
  .section-4-square-top-right {
    width: 50%;
  }
  .section-4-square-bottom-box-top div:nth-child(3) {
    width: 25%;
  }
  .section-4-background {
    background-size: contain;
    /*height: 600px;*/
  }
  .section-4-background-container {
    /*height: 455px;*/
  }
  .section-4-middle {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .section-4-background-container {
    height: 400px;
  }
  .section-4-background {
    height: 100%;
    background-size: cover;
  }
}

@media screen and (max-width: 600px) {
  .section-4-background-container {
    height: 700px;
  }
  .section-4-background {
    height: 100%;
    background-size: cover;
  }
  .modal-footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .modal-footer-image-box {
    margin-right: 0;
  }
  .modal-footer .company-image {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .modal-footer-products {
    justify-content: center;
  }
  .modal-footer-products .product-image {
    margin-right: 0;
  }
}
